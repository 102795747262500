import banner from './resources/images/banner.png';
import ad1 from './resources/images/ad1.jpg';
import ad2 from './resources/images/ad2.jpg';
import logo from './resources/images/logo.png';
import './App.css';
import React, { Component } from 'react';

import { services } from './services/services';
import moment from 'moment';
import { PeriodType } from './mytype/period';

const timeAnnounce = moment().format('YYYY-MM-DD 19:50:00');
const announceStartDate = moment(timeAnnounce).format('YYYY-MM-DD HH:mm:ss');
const beforeStartUpAnnounceDate = moment(timeAnnounce).format('YYYY-MM-DD HH:mm:ss');
const afterStartUpAnnounceDate = moment(timeAnnounce).add(4.59, 'minute').format('YYYY-MM-DD HH:mm:ss');
const startUpAnnounceDate = moment(timeAnnounce).add(5, 'minute').format('YYYY-MM-DD HH:mm:ss');
const endUpAnnouneDate = moment(timeAnnounce).add(9.59, 'minute').format('YYYY-MM-DD HH:mm:ss');
const upAnnounceDate = moment(timeAnnounce).add(10, 'minute').format('YYYY-MM-DD HH:mm:ss');
const announceEndDate = moment(timeAnnounce).add(10, 'minute').format('YYYY-MM-DD HH:mm:ss');

const imageLoading = () => {
  return <img
    style={{ marginRight: 2 }}
    src={require('./resources/images/loading.gif')}
    width={50}
    height={50}
    alt='number'
  />;
}

class App extends Component<any, any> {

  _isMounted: boolean = false;
  animationTimer: any = null;
  upTimer: any = null;

  constructor(props: any) {
    super(props);

    this.state = {
      isMobile: false,
      periods: null,
      previousPeriod: null,
      currentPeriod: null,
      numberAnnounce: {
        number1: imageLoading(),
        number2: imageLoading(),
        number3: imageLoading(),
        number4: imageLoading(),
        number5: imageLoading()
      },
      todayDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      oldUpAnnounce: false,
      beforeAnnonce: false,
      beforeStartUp: false,
      upAnnounce: false,
      afterAnnounce: false,
      isShowing: false,
    }
  }

  componentDidMount() {
    this.getAllStatistics();
    this.initData();

    window.addEventListener("resize", this.resizeWindow);
    this.resizeWindow();
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.animationTimer);
    clearInterval(this.upTimer);
    window.removeEventListener("resize", this.resizeWindow);
  }

  resizeWindow = () => {
    const width = window.innerWidth;
    if (width <= 500) {
      this.setState({ isMobile: true });
    }
    else {
      this.setState({ isMobile: false });
    }
  }

  initData = () => {
    this.animationTimer = setInterval(() => {
      const { todayDate } = this.state;
      this.setState({ todayDate: moment().format('YYYY-MM-DD HH:mm:ss') });
      if (moment(todayDate).toDate().getTime() < moment(announceStartDate).toDate().getTime()) {
        this.beforeAnnonce();
      } else if (
        moment(todayDate).toDate().getTime() >= moment(announceStartDate).toDate().getTime()
        && moment(todayDate).toDate().getTime() <= moment(announceEndDate).toDate().getTime()
      ) {
        this.intervalData();
      } else {
        this.afterAnnounce();
      }
    }, 1000);
  }

  beforeAnnonce = async () => {
    const { todayDate, beforeAnnonce } = this.state;
    if (!beforeAnnonce) {
      const dateNow = moment(todayDate).add(-1, 'd').format("YYYY-MM-DD 20:00:00");
      const period = await services.getPeriods(dateNow, 'both', '22');
      const up = period.data.up6;
      this.setState({
        numberAnnounce: {
          number1: up[0],
          number2: up[1],
          number3: up[2],
          number4: up[3],
          number5: up[4]
        },
        beforeAnnonce: true,
        isShowing: true
      });
    }

  }

  intervalData = async () => {
    const { todayDate, oldUpAnnounce, upAnnounce, beforeStartUp } = this.state;

    if (moment(todayDate).toDate() < moment(startUpAnnounceDate).toDate()) {
      if (!oldUpAnnounce) {
        this.setState({
          numberAnnounce: {
            number1: imageLoading(),
            number2: imageLoading(),
            number3: imageLoading(),
            number4: imageLoading(),
            number5: imageLoading()
          },
          oldUpAnnounce: true,
          isShowing: false
        });
      }
    }

    if (moment(todayDate).toDate() >= moment(beforeStartUpAnnounceDate).toDate() && moment(todayDate).toDate() <= moment(afterStartUpAnnounceDate).toDate()) {
      if (!beforeStartUp) {
        this.setState({
          numberAnnounce: {
            number1: imageLoading(),
            number2: imageLoading(),
            number3: imageLoading(),
            number4: imageLoading(),
            number5: imageLoading(),
            isShowing: false
          },
          beforeStartUp: true
        });
      }
    }

    if (moment(todayDate).toDate() >= moment(startUpAnnounceDate).toDate() && moment(todayDate).toDate() <= moment(endUpAnnouneDate).toDate()) {
      if (this.upTimer === null) {
        this.upTimer = setInterval(() => {
          this.setState({
            numberAnnounce: {
              number1: Math.floor(Math.random() * 10),
              number2: Math.floor(Math.random() * 10),
              number3: Math.floor(Math.random() * 10),
              number4: Math.floor(Math.random() * 10),
              number5: Math.floor(Math.random() * 10)
            },
            isShowing: false
          })
        }, 100);
      }
    }

    if (moment(todayDate).toDate().getTime() >= moment(upAnnounceDate).toDate().getTime()) {
      clearInterval(this.upTimer);
      if (!upAnnounce) {
        // call api and show up 
        const statisticToday = await services.getStatisticToday();
        // await this.getCurrentPeriod();
        const up = statisticToday.data.up6;

        this.setState({
          numberAnnounce: {
            number1: up[0],
            number2: up[1],
            number3: up[2],
            number4: up[3],
            number5: up[4]
          },
          upAnnounce: true,
          isShowing: true
        })
      }
    }
  }

  afterAnnounce = async () => {
    clearInterval(this.upTimer);
    const { afterAnnounce } = this.state;
    if (!afterAnnounce) {
      const statisticToday = await services.getStatisticToday();
      const up = statisticToday.data.up6;
      this.setState({
        numberAnnounce: {
          number1: up[0],
          number2: up[1],
          number3: up[2],
          number4: up[3],
          number5: up[4]
        },
        afterAnnounce: true,
        isShowing: true
      })
    }
  }

  getAllStatistics = async () => {
    const response = await services.getStatistics();
    this.setState({
      periods: response.data
    })
  }

  renderPeriods() {
    const { periods } = this.state;

    if (!periods) {
      return (
        <tr className="text-center">
          <td colSpan={7}>Không có thông tin</td>
        </tr>
      );
    }

    if (periods.length === 0) {
      return (
        <tr className="text-center">
          <td colSpan={7}>Không có thông tin</td>
        </tr>
      );
    }

    return periods.map((period: PeriodType, index: number) => {

      if (index <= 4) {
        return (
          <tr key={index} className="text-center">
            {/* <td>{utils.convertToThaiDate(period?.dateAnnounce.toString())}</td> */}
            <td>{moment(period.dateAnnounce).format("DD-MM-YYYY")}</td>
            <td>{period.up3}</td>
            <td>{period.down2}</td>
          </tr>
        );
      }

    });
  }

  renderDateTime = () => {
    const { todayDate } = this.state;
    if (moment(todayDate).toDate().getTime() >= moment(beforeStartUpAnnounceDate).toDate().getTime()) {
      return `Kết quả xổ số Hà Nội Super ${moment().format("DD/MM/YYYY")}`;
    }

    return `Kết quả xổ số Hà Nội Super ${moment().add(-1, 'd').format("DD/MM/YYYY")}`;
  }

  render(): React.ReactNode {

    const { numberAnnounce, isMobile } = this.state;

    return (
      <div className="container p-0">
        <div className="header d-flex justify-content-center">
          <img src={banner} className="img-fluid" height="250px" alt="Hanoi Super" />
        </div>
        <div className="row pt-1">
          <div className="col-md-8 col-sm-12 col-xs-12">
            <div className="announce">
              <div>
                <div className="d-flex justify-content-center mt-0">
                  <div className="align-self-center p-3">
                    <img src={logo} className="img-fluid" height="70px" width="120px" alt="Hanoi Super" />
                  </div>
                  <div className="p-1" style={{ display: 'table' }}>
                    <h4 style={{ fontSize: 30, margin: 0, display: 'table-cell', textAlign: 'center', verticalAlign: 'middle' }}>{this.renderDateTime()}</h4>
                  </div>
                </div>
                <hr />
                <table className={`table table-bordered mb-0 ${isMobile ? '' : 'table-announce'}`}>
                  <tbody>
                    <tr>
                      <td style={{ fontSize: 36, textAlign: 'center', verticalAlign: 'middle', borderColor: 'white' }}>
                        <span>Giải siêu</span>
                      </td>
                      <td colSpan={2} style={{ padding: 0, textAlign: 'center', verticalAlign: 'middle', borderColor: 'white' }}>
                        {/* <span style={{ fontSize: 55, fontWeight: 1000, letterSpacing: 4, fontFamily: 'PSL', color: 'red' }}>56578</span> */}
                        <h1 style={{ fontSize: 55, fontWeight: 1000, letterSpacing: 4, fontFamily: 'PSL', color: 'red', marginTop: 13 }}>
                          <span id="n1">{numberAnnounce?.number1}</span>
                          <span id="n2">{numberAnnounce?.number2}</span>
                          <span id="n3">{numberAnnounce?.number3}</span>
                          <span id="n4">{numberAnnounce?.number4}</span>
                          <span id="n5">{numberAnnounce?.number5}</span>
                        </h1>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="text-center p-4 mt-4 mb-2" style={{ backgroundColor: '#8b0404' }}>
                </div>
              </div>
            </div>
            <div className="card d-flex justify-content-center mt-0">
              <div className="card-body p-0 point">
                <img src={ad1} width="100%" alt="หวย หวยลาว ลาว ຫວຍແມ່ຂອງVIP หวยแม่โขงVIP ผลหวยแม่โขงVIP" />
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 col-xs-12">

            <div className="text-center p-1 mt-4 mb-2" style={{ backgroundColor: '#8b0404' }}>
              <h5 className="text-center" style={{ color: 'white' }}>Kết quả xổ số trực tiếp trước đó</h5>
            </div>
            <table className="table table-bordered table-striped m-0">
              <thead>
                <tr className="text-center">
                  <th style={{ fontSize: 14 }}>Ngày trao giải</th>
                  <th style={{ fontSize: 14 }}>Giải nhất</th>
                  <th style={{ fontSize: 14 }}>Giải nhì</th>
                </tr>
              </thead>
              <tbody style={{ fontFamily: 'PSL', fontSize: 14, textAlign: 'center' }}>
                {this.renderPeriods()}
              </tbody>
            </table>

            <div className="card d-flex justify-content-center mt-0">
              <div className="card-body p-0 point">
                <img src={ad2} width="100%" alt="หวย หวยลาว ลาว ຫວຍແມ່ຂອງVIP หวยแม่โขงVIP ผลหวยแม่โขงVIP" />
              </div>
            </div>
          </div>
        </div>
      </div >
    )
  }
}

export default App;